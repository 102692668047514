import axios from "axios";
import interceptor from "./interceptor";

const request = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL, // 开发环境的URL
  timeout: 20000, // 请求超时时间
});

interceptor(request);

export default request;
