import * as StorageHelper from "@/utils/StorageHelper";
import { Toast } from "vant";

function intercept(request) {
  request.interceptors.request.use(
    (options) => {
      options.headers["Blade-Auth"] =
        "bearer " + StorageHelper.getAccessToken();
      options.headers["Authorization"] =
        "Basic MTAwMDAwOTo0MVVnWnBoYms4ZVE1TmZ6MlhuZA==";
      options.headers["Tenant-Id"] = "000000";
      options.headers["Corp-Id"] = process.env.VUE_APP_CROP_ID;
      return options;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  request.interceptors.response.use(
    (response) => {
      return response.data;
    },
    (error) => {
      if (error.response.data) {
        if (error.response.status === 400) {
          Toast("请求出错");
        } else if (error.response.status === 401) {
          Toast("请求未授权");
        } else if (error.response.status === 404) {
          Toast("找不到服务器");
        } else if (error.response.status === 500) {
          Toast("服务器挂了");
        } else {
          Toast(error.response.data.msg);
        }
      }
      return Promise.reject(new Error(error.response.statusText));
    }
  );
}

export default intercept;
